<template>
  <v-container
    fill-height
    fluid
    class="pa-0"
  >
    <v-row justify="center">
      <v-card
        flat
        width="350"
        class="mx-auto"
      >
        <div class="d-flex justify-center">
          <v-img
            height="150"
            width="150"
            :src="require('@/assets/logo.png')"
          ></v-img>
        </div>
        <h1 class="text-center mb-5 mt-2">Admin Login</h1>
        <v-form
          v-model="loginValid"
          ref="form"
          @submit.prevent="logIn()"
        >
          <v-text-field
            v-model="email"
            placeholder="Admin email"
            class="mt-2"
            outlined
            dense
            color="primary"
            type="email"
            :rules="emailRules"
            required>
          </v-text-field>
          <v-text-field
            v-model="password"
            placeholder="Admin password"
            class="mt-2"
            outlined
            dense
            color="primary"
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            required>
          </v-text-field>
          <div class="mt-2 pb-5">
            <v-btn
              block depressed
              color="primary"
              type="submit"
              :loading="loginLoading"
              :disabled="loginLoading || !loginValid">
              Login
              <template v-slot:loader>
                Logging in...
              </template>
            </v-btn>
            <div class="pt-3">
              <a href="/">Back to website</a>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'login',
    data() {
      return {
        loginValid: false,
        loginLoading: false,
        email: '',
        emailRules :[
          email => !!email || 'Please enter your email here',
          email => (email && /.+@.+/.test(email)) || 'Please check email format'
        ],
        password: '',
        showPassword: false,
        passwordRules: [
          password => !!password || 'Please enter a password here'
        ],
      }
    },
    methods: {
      async logIn() {
        this.loginLoading = true
        await apiService.create('auth/sign_in', { email: this.email, password: this.password }).then(res => {
          this.loginLoading = false
          localStorage.setItem('adminAuth', JSON.stringify(res.data.data))
          if (res.data.data.role_id == 1) {
            this.$router.push({ name: 'dashboard'})
          } else {
            this.$router.push({ name: 'orders'})
          }
          this.$store.dispatch('showSnackbar', { content: 'Welcome back!', status: 'success' })
        }).catch(err => {
          this.loginLoading = false
          this.$store.dispatch('showSnackbar', { content: err.response.data.errors[0] || 'Incorrect email or password entered', status: 'error' })
        })
      }
    }
  }
</script>

<style>
  .login-container {
    display: flex;
    height: 100vh;
    align-items: center;
  }
</style>
